<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <span v-if="numberingRecord.objecttype"
            >{{ numberingRecord.objecttype.DocumentName }} - Series Setup</span
          >
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text to="/document-numbering">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row dense no-gutters>
          <v-col cols="12">
            <v-btn color="accent" small @click="openDefaultDialog">
              <v-icon left>mdi-plus</v-icon>Set As Default
            </v-btn>

            <v-btn right absolute color="accent" small @click="openSeriesDialog">
              <v-icon left>mdi-plus</v-icon>Define New
            </v-btn>
          </v-col>
        </v-row>

        <!-- series list -->
        <v-row>
          <v-text-field dense v-model="seriesSearch" label="Search"></v-text-field>
          <v-col cols="12">
            <v-data-table
              hide-default-footer
              v-model="selectedItems"
              :headers="headers"
              :items="seriesData"
              :items-per-page="itemsPerPage"
              :search="seriesSearch"
              :single-select="singleSelect"
              @item-selected="clickedItem"
              show-select
            >
              <!-- lock and cancellation editor -->
              <template v-slot:item.Locked="props">
                <v-checkbox
                  true-value="Y"
                  false-value="N"
                  v-model="props.item.Locked"
                ></v-checkbox>
              </template>
              <!-- end -->

              <!-- lock and cancellation editor -->
              <template v-slot:item.IsForCncl="props">
                <v-checkbox
                  true-value="Y"
                  false-value="N"
                  v-model="props.item.IsForCncl"
                ></v-checkbox>
              </template>
              <!-- end -->
            </v-data-table>
          </v-col>

          <v-col col="12">
            <v-btn class="accent" :loading="updateLoading" @click="updateSeries" small
              >Update</v-btn
            >
          </v-col>
        </v-row>
        <!-- end of series list -->

        <!-- start of entry dialog -->
        <v-dialog v-model="dialog" max-width="900px">
          <v-card>
            <v-toolbar dense dark color="modalToolbar">
              <v-toolbar-title>New Series Setup</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="4">
                    <v-text-field
                      label="Series Code"
                      v-model="record.Series"
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Name"
                      v-model="record.SeriesName"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="First Number"
                      type="Number"
                      v-model="record.InitialNum"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Last Number"
                      type="Number"
                      v-model="record.LastNum"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Prefix"
                      v-model="record.BeginStr"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Suffix"
                      v-model="record.EndStr"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Remarks"
                      v-model="record.Remark"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="No Of Digits."
                      v-model="record.NumSize"
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-checkbox
                      v-model="IsForCncl"
                      true-value="Y"
                      false-value="N"
                      label="Cancellation"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="2">
                    <v-checkbox
                      v-model="Locked"
                      true-value="Y"
                      false-value="N"
                      label="Lock"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-btn color="accent" :loading="loading" @click="saveSeries">
                      <v-icon left>mdi-content-save</v-icon>Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of entry dialog -->

        <!-- start of default dialog -->
        <v-dialog
          v-model="defaultDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="accent">
              <v-toolbar-title>
                <v-btn icon dark @click="defaultDialog = false">
                  <v-icon dark>mdi-keyboard-backspace</v-icon> </v-btn
                >Set Default Numbering series
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="defaultDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row justify="center">
                <v-col cols="6">
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Series: {{ selectedItem.SeriesName }}</h4>
                    </v-col>

                    <v-col cols="12">
                      <v-radio-group v-model="userGroup">
                        <v-radio
                          label="Set as default for current user"
                          value="one"
                        ></v-radio>
                        <v-radio
                          label="Set as default for all users"
                          value="all"
                        ></v-radio>
                        <v-radio
                          label="Set as default for certain users"
                          value="certain"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col cols="12">
                      <v-btn class="accent" small @click="setDefault">Ok</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of default dialog -->

        <!-- users dialog -->
        <v-dialog v-model="usersDialog" max-width="600px">
          <v-card>
            <v-toolbar dark color="accent">
              <v-toolbar-title>Select Users</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="usersDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row class="pt-4">
                <v-text-field dense v-model="userSearch" label="Search"></v-text-field>
                <v-col cols="12">
                  <v-data-table
                    :search="userSearch"
                    v-model="selectedUsers"
                    :headers="userHeaders"
                    :items="users"
                    show-select
                    item-key="id"
                  ></v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn color="accent" small @click="setCertainUsers">Ok</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of users list dialog -->

        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      userGroup: null,
      itemsPerPage: 100,
      dialog: false,
      userSearch: null,
      seriesSearch: null,
      usersDialog: false,
      defaultDialog: false,
      loading: false,
      updateLoading: false,
      singleSelect: true,
      selectedItem: {},
      selectedItems: [],
      selectedUsers: [],
      users: [],
      Locked: "N",
      IsForCncl: "N",
      search: null,
      numberingRecord: {},
      record: {},
      seriesData: [],
      headers: [
        { text: "Name", value: "SeriesName" },
        { text: "Series Code", value: "Series" },
        { text: "First Number", value: "InitialNum" },
        { text: "Next Number", value: "NextNumber" },
        { text: "Last Number", value: "LastNum" },
        { text: "Prefix", value: "BeginStr" },
        { text: "Suffix", value: "EndStr" },
        { text: "External Ref", value: "ExtRef" },
      ],
      userHeaders: [{ text: "Name", value: "name" }],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true,
    },
  },
  methods: {
    updateSeries() {
      const url = "/documentnumbering/updateseries";
      const self = this;
      const data = { Series: this.seriesData };
      self.updateLoading = true;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    setCertainUsers() {
      this.selectedUsers;
      const series = this.selectedItem;
      const ids = this.selectedUsers.map((record) => {
        return record.id;
      });
      const data = { Series: series.id, users: ids };
      const url = "/documentnumbering/set-default-selected-users";
      this.saveDefaults(url, data);
      this.usersDialog = false;
    },
    setDefault() {
      let data = {};
      let url = null;
      const series = this.selectedItem;
      if (this.userGroup == "one") {
        data = { Series: series.id };
        url = "/documentnumbering/set-default-current-user";
        this.saveDefaults(url, data);
      } else if (this.userGroup == "all") {
        data = { Series: series.id };
        url = "/documentnumbering/set-default-all-users";
        this.saveDefaults(url, data);
      } else {
        this.usersDialog = true;
      }
    },
    openSeriesDialog() {
      this.dialog = true;
    },
    selectedRadio($MouseEvent) {
      console.log($MouseEvent);
      console.log(this.userGroup, "user group");
    },
    clickedItem($event) {
      this.selectedItem = $event.item;
    },
    openDefaultDialog() {
      if (this.selectedItem.SeriesName == undefined) {
        this.$refs.snackbar.show("Please select a series first!", "red");
      } else {
        this.defaultDialog = true;
      }
    },
    saveDefaults(url, data) {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$refs.snackbar.show("Default series created successfully", "green");
          self.defaultDialog = false;
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    saveSeries() {
      const LastNumLength = this.record.LastNum.length;
      const NumSize = this.record.NumSize;
      if (NumSize !== undefined && LastNumLength > NumSize) {
        this.$refs.snackbar.show("Last Number length greater than No. of Digits", "red");
      } else {
        this.record.ObjectCode = this.numberingRecord.ObjectCode;
        this.record.Locked = this.Locked || null;
        this.record.IsForCncl = this.IsForCncl || null;
        const data = this.record;
        const url = "/documentnumbering/createseries";
        const self = this;
        self.loading = true;
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            self.loading = false;
            if (res.ResultCode == 1200) {
              self.$refs.snackbar.show("Created Successfully", "green");

              this.getData(this.$route.params.id);
            } else {
              this.$refs.snackbar.show(res.ValidationError, "red");
            }
          })
          .catch((err) => {
            self.loading = false;
            this.$refs.snackbar.show(err.response.data.errors, "red");
          });
      }
    },
    getData(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/documentnumbering/${val}`)
        .then((res) => {
          self.loader = false;
          this.dialog = false;
          if (res.ResultCode == 1200) {
            self.numberingRecord = res.ResponseData;
            self.seriesData = res.ResponseData.series;
          } else {
            this.$refs.snackbar.show(res.ResponseData.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped></style>
